<template>
    <div id="avaliacao" class="h-100">
        <div :class="'m_'+id_marca" v-if="(!falhou && !ja_respondeu && !finalizado && !loading)">
            <div class="bg_marca">
                <img class="logo_marca" v-bind:src="url_logo" alt="Logo da Empresa" >
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="cliente_marca">
                Olá, {{cliente}}
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="mensagem_marca">
                <p>
                    {{vendedor}}, entrou<br>
                    em contato com você via <strong color="#707070">telefone</strong>.
                    <br>
                    Agora queremos saber como foi.
                </p>
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="pergunta_marca">
                Com qual nota você avalia o nosso contato?
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="container_selecao">
                <table width="100%">
                    <tbody>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(1)">1</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(2)">2</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(3)">3</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(4)">4</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(5)">5</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(6)">6</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(7)">7</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(8)">8</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(9)">9</button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="bg_marca_selecao">
                            <button type="button" @click="sendPontuacao(10)">10</button>
                        </td>
                        <td>&nbsp;</td>
                    </tbody>
                </table>
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="container_linha_selecao">
                <hr class="linha">
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="pergunta_marca">
                Caso você não tenha recebido o contato, por favor,<br>
                selecione o motivo:
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="container_negativa">
                <table width="100%">
                    <tbody>
                        <td>&nbsp;</td>
                        <td class="negativa_marca">
                            <button type="button" @click="sendNaoSouClienteInformado($event)">
                                Não sou o cliente informado
                            </button>
                        </td>
                        <td>&nbsp;</td>
                        <td class="negativa_marca">
                            <button type="button" @click="sendNaoHouveContato($event)">
                                Não recebi o contato
                            </button>
                        </td>
                        <td>&nbsp;</td>
                    </tbody>
                </table>
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="container_linha">
                <hr class="linha">
            </div>
            <div class="custom-height-22">&nbsp;</div>
            <div class="mensagem_marca">
                <strong>Obrigado!</strong>
            </div>
        </div>
        <div :class="'m_'+id_marca" v-if="ja_respondeu">
            <div class="bg_marca">
                <img class="logo_marca" v-bind:src="url_logo" alt="Logo da Empresa" >
            </div>
            <div class="container_ja_respondeu">
                <div class="custom-height-60">&nbsp;</div>
                <div class="mensagem">
                    <p>
                        {{cliente}},<br>
                        você já fez sua avaliação anteriormente,<br>
                        agradecemos por sua avaliação!
                    </p>
                </div>
            </div>
        </div>
        <div :class="'m_'+id_marca" v-if="finalizado">
            <div class="bg_marca">
                <img class="logo_marca" v-bind:src="url_logo" alt="Logo da Empresa" >
            </div>
            <div class="container_finalizado">
                <div class="custom-height-60">&nbsp;</div>
                <div class="mensagem">
                    <p>
                        {{cliente}},<br>
                        {{mensagem_finalizado}}
                    </p>
                </div>
            </div>
        </div>
        <div class="container_loader" v-if="loading">
            <div class="loader"></div>
            <div class="loader_texto">Carregando</div>
        </div>
    </div>
</template>
<script>
import store from '@/store';
import '../../assets/css/avaliacao.scss';
import {Utils} from "../../components/utils";
export default {
    name: 'avaliacao',
    props: ['code'],
    mixins: [Utils],
    data() {
        return {
            loading: false,
            finalizado: false,
            ja_respondeu: false,
            falhou: false,
            id_marca: '',
            url_logo: '',
            vendedor: '',
            cliente: '',
            mensagem_finalizacao: ''
        };
    },
    methods: {
        sendPontuacao(pontuacao) {
            // Bloquear atualizar página por causa do submit
            this.loading = true;
            if (this.verificaCodigoPesquisa()) {
                const payload = {
                    research_id: this.code,
                    research_note: pontuacao
                };
                store.dispatch('AvaliarAtendimento', payload)
                .then((json) => {
                    if (json.error === false) {
                        this.mensagem_finalizado = json.message;
                        this.finalizado = true;
                    }
                    else {
                        alert(json.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;

                alert('Ocorreu um erro inesperado');
            }
        },
        sendNaoSouClienteInformado(evt) {
            evt.preventDefault();
            this.loading = true;
            if (this.verificaCodigoPesquisa()) {
                const payload = {
                    research_id: this.code,
                    research_its_not_me: 'S'
                };
                store.dispatch('AvaliarAtendimento', payload)
                .then((json) => {
                    if (json.error === false) {
                        this.mensagem_finalizado = json.message;
                        this.finalizado = true;
                    }
                    else {
                        alert(json.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
                alert('Ocorreu um erro inesperado');
            }
        },
        sendNaoHouveContato(evt) {
            evt.preventDefault();

            if (this.verificaCodigoPesquisa()) {
                const payload = {
                    research_id: this.code,
                    research_no_contact: 'S'
                };
                store.dispatch('AvaliarAtendimento', payload)
                .then((json) => {
                    if (json.error === false) {
                        this.mensagem_finalizado = json.message;
                        this.finalizado = true;
                    }
                    else {
                        alert(json.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
            } else {
                alert('Ocorreu um erro inesperado');
            }
        },
        verificaCodigoPesquisa() {
            return (this.code != null && typeof this.code != 'undefined');
        },
        verificaJaRespondeu(dados) {
            return (dados.DataResposta != null);
        }
    },
    created() {
        
        this.loading = true;
        const payload = {
            research_id: this.code
        };

        store.dispatch('GetDadosFormularioAvaliacao', payload)
        .then((json) => {
            if (json.error === false && json.content != null) {
                this.cliente = json.content.ClienteNome;
                this.vendedor = json.content.FuncNome;
                this.id_marca = json.content.MarcaIdo;
                this.url_logo = 'http://static.zzvendas.com.br/relacionamento/marca_'+json.content.MarcaIdo+'.svg';
                this.ja_respondeu = this.verificaJaRespondeu(json.content);
            }
            else {
                this.falhou = true;
                document.location.href = '/page-not-found';
                alert(json.message);
            }
        })
        .finally(() => {
            this.loading = false;
        });
    }
}
</script>